import { Modal, Select } from "antd";
import React, { useEffect, useState } from "react";

const SettingsModal = ({open, setOpen, settingsObject, setSettingsObject}) => {
const [isModalOpen, setIsModalOpen] = useState(open);
   useEffect(() => {
    setIsModalOpen(open);
   }, [open]);

  const handleOk = (event) => {
    setOpen(false);
    localStorage.setItem("settings", JSON.stringify(settingsObject));
  };

  const handleCancel = (event) => {
    setOpen(false);
  };

  const handleStyleChange = (value) => {
    setSettingsObject({...settingsObject, codeEditorStyle: value});
  };

  const codeStyles = [
        { value: 'monokai', label: 'monokai' },
        { value: 'github', label: 'github' },
        { value: 'tomorrow', label: 'tomorrow' },
        { value: 'kuroir', label: 'kuroir' },
        { value: 'twilight', label: 'twilight' },
        { value: 'xcode', label: 'xcode' },
        { value: 'textmate', label: 'textmate' },
        { value: 'solarized_dark', label: 'solarized_dark' },
        { value: 'solarized_light', label: 'solarized_light' },
    ]

  return (
    <>
        <Modal width="min-content" title="Settings" open={isModalOpen} onCancel={handleCancel} onOk={handleOk}>
            <div className="flex-column gap-m padding-m">
                <div>
                    <span className="label"> Code editor theme: </span> 
                    <Select
                        defaultValue={settingsObject.codeEditorStyle}
                        style={{ width: 120 }}
                        onChange={handleStyleChange}
                        options={codeStyles}
                    />
                </div>
            </div>


        </Modal>
    </>
  );
};

export default SettingsModal;
